<template>
    <div>
        <div class="modal-header">
            <div class="modal-title-my flex-1">
                <div class="title-f-l">
                    <p class="mb-0">F.I.SH: <b>{{ selectedItem.surname ?  selectedItem.surname : '' }}  {{ selectedItem.name ? selectedItem.name : '' }} 
                      {{ selectedItem.lastname ? selectedItem.lastname : '' }}</b></p>
                    <div class="right-rez add-right-rez" :class="prosent_color_doctor"><span><b>{{ selectedItem.match_prosent }} % </b> </span></div>
                </div>
            </div>
            <div>
                <el-button type="danger" @click="close()" plain>
                    Yopish
                </el-button>
            </div>
        </div>
        
        <div class="p-20">
            <!-- Passport -->
            <table class="rez-khan-table" v-if="has_passport">
                <thead>
                    <tr>
                        <th colspan="2" class="text-center">Passport seriyasi va raqami</th>
                    </tr>
                    <tr>
                        <th>Pasport</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{passport.passport_series_and_number ? passport.passport_series_and_number : 'Mavjud emas'}}
                        </td>
                        <td>
                            <i v-if="passport.status"  class="success-icon el-icon-circle-check"></i> 
                            <i v-else  class="danger-icon el-icon-circle-close"></i> 
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="passport.hasOwnProperty('similler_passports')">
                <table class="rez-khan-table" v-if="passport.similler_passports.length">
                <thead>
                    <tr>
                        <th colspan="2" class="text-center">Bir xil PASSPORT seriyasi mavjud</th>
                    </tr>
                    <tr>
                        <th>Tibbiy muassasa</th>
                        <th>F.I.SH</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item_doctor, index) in passport.similler_passports" :key="'passport-'+index">
                        <td>
                            {{ item_doctor.clinic }}
                        </td>
                        <td>
                            {{item_doctor.surname}} {{item_doctor.name}} {{item_doctor.lastname ? item_doctor.lastname : ''}}
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>

            <!-- INN -->
            <table class="rez-khan-table" v-if="has_inn">
                <thead>
                    <tr>
                        <th colspan="2" class="text-center">Inn</th>
                    </tr>
                    <tr>
                        <th>Raqam</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{ inn.inn_number ? inn.inn_number : 'Mavjud emas' }}
                        </td>
                        <td>
                            <i v-if="inn.status"  class="success-icon el-icon-circle-check"></i> 
                            <i v-else  class="danger-icon el-icon-circle-close"></i> 
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="inn.hasOwnProperty('similler_inns')">
                <table class="rez-khan-table" v-if="inn.similler_inns.length">
                    <thead>
                        <tr>
                            <th colspan="2" class="text-center">Bir xil INN raqamlar mavjud</th>
                        </tr>
                        <tr>
                            <th>Tibbiy muassasa</th>
                            <th>F.I.O</th>
                        </tr>
                    </thead>
                    <tbody v-for="(item_doctor, index) in inn.similler_inns" :key="'inn-'+index">
                        <tr>
                            <td>
                                {{ item_doctor.clinic }}
                            </td>
                            <td>
                                {{ item_doctor.surname }} {{ item_doctor.name }} {{ item_doctor.lastname ? item_doctor.lastname : '' }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

             <!-- Viloyat -->
            <table class="rez-khan-table" v-if="has_region">
                <thead>
                    <tr>
                        <th  colspan="3" class="text-center">Viloyat</th>
                    </tr>
                    <tr>
                        <th>Talab</th>
                        <th>Viloyat</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <span v-if="region.req_type">Shart</span>
                            <span v-else>Shart emas</span>
                        </td>
                        <td>
                            <span>{{ region.region_name ? region.region_name : 'Mavjud emas'}}</span>
                        </td>
                        <td>
                            <i v-if="region.status" class="success-icon el-icon-circle-check"></i> 
                            <i v-else class="danger-icon el-icon-circle-close"></i>
                        </td>
                    </tr>
                </tbody>
            </table>

             <!-- ISH tajribasi -->
            <table class="rez-khan-table" v-if="has_experience">
                <thead>
                    <tr>
                        <th colspan="3" class="text-center">Ish tajribasi</th>
                    </tr>
                    <tr>
                        <th>Talab</th>
                        <th>Natija</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <span v-if="experience.from && experience.until"> {{experience.from}} yildan {{experience.until}} yilgacha </span>
                            <span v-else>Mavjud emas</span>
                        </td>
                        <td>
                            <span v-if="experience.amount"> {{experience.amount}} yil </span>
                            <span v-else>Mavjud emas</span>
                        </td>
                        <td>
                            <i v-if="experience.status" class="success-icon el-icon-circle-check"></i> 
                            <i v-else class="danger-icon el-icon-circle-close"></i> 
                        </td>
                    </tr>
                </tbody>
            </table>

             <!-- Xodim bitirgan OTM -->
            <div v-if="educations.hasOwnProperty('req_educations')">
                <table class="rez-khan-table" v-if="educations.req_educations.length">
                    <thead>
                        <tr>
                            <th colspan="2" class="text-center">OTM</th>
                        </tr>
                        <tr>
                            <th>Talab</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item_doctor, index) in educations.req_educations" :key="'educations-'+index">
                            <td>
                                {{item_doctor.name ? item_doctor.name : 'Mavjud emas'}}
                            </td>
                            <td>
                                <i v-if="item_doctor.status" class="success-icon el-icon-circle-check"></i> 
                                <i v-else class="danger-icon el-icon-circle-close"></i> 
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="educations.hasOwnProperty('employee_educations')">
                <table class="rez-khan-table" v-if="educations.employee_educations.length">
                    <thead>
                        <tr>
                            <th  class="text-center"> OTM</th>
                        </tr>
                        <tr>
                            <th>Xodim bitirgan</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr v-for="(item_doctor, index) in educations.employee_educations" :key="'employee_educations-'+index">
                            <td>
                                {{item_doctor.name ? item_doctor.name : 'Mavjud emas'}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <!-- Xodim Malaka Toifasi -->
            <div v-if="category.hasOwnProperty('req_categories')">
                <table class="rez-khan-table" v-if="category.req_categories.length">
                    <thead>
                        <tr>
                            <th colspan="2" class="text-center">Malaka Toifasi</th>
                        </tr>
                        <tr>
                            <th>Talab</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item_doctor, index) in category.req_categories" :key="'categories-'+index">
                            <td>
                                {{ item_doctor.name ? item_doctor.name : 'Mavjud emas'}}
                            </td>
                            <td>
                                <i v-if="item_doctor.status" class="success-icon el-icon-circle-check"></i> 
                                <i v-else class="danger-icon el-icon-circle-close"></i> 
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="category.hasOwnProperty('employee_categories')">
                <table class="rez-khan-table" v-if="category.employee_categories.length">
                    <thead>
                        <tr>
                            <th  class="text-center"> Malaka Toifasi</th>
                        </tr>
                        <tr>
                            <th>Xodim malaka Toifasi</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr v-for="(item_doctor, index) in  category.employee_categories" :key="'employee_categories-'+index">
                            <td>
                                {{item_doctor.name ? item_doctor.name : 'Mavjud emas'}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        
            <!-- Ilmiy Daraja qnday -->
            <div v-if="degree.hasOwnProperty('req_academic_degrees')">
                <table class="rez-khan-table" v-if="degree.req_academic_degrees.length">
                    <thead>
                        <tr>
                            <th colspan="2" class="text-center">Ilmiy Daraja</th>
                        </tr>
                        <tr>
                            <th>Talab</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item_doctor, index) in degree.req_academic_degrees" :key="'req_academic_degrees-'+index">
                            <td>
                                {{item_doctor.name ? item_doctor.name : 'Mavjud emas'}}
                            </td>
                            <td>
                                <i v-if="item_doctor.status" class="success-icon el-icon-circle-check"></i> 
                                <i v-else class="danger-icon el-icon-circle-close"></i> 
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="degree.hasOwnProperty('employee_degrees')">
                <table class="rez-khan-table" v-if="degree.employee_degrees.length">
                    <thead>
                        <tr>
                            <th  class="text-center">Ilmiy Daraja</th>
                        </tr>
                        <tr>
                            <th>Xodim ilmiy darajasi</th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr v-for="(item_doctor, index) in degree.employee_degrees" :key="'employee_degrees-'+index">
                            <td>
                                {{item_doctor.name ? item_doctor.name : 'Mavjud emas'}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <!-- Boshqaruvda -->
            <table class="rez-khan-table" v-if="has_steerage">
                <thead>
                    <tr>
                        <th  colspan="3" class="text-center">Boshqaruvda xodim</th>
                    </tr>
                    <tr>
                        <th>Talab</th>
                        <th>Xodim</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <span v-if="steerage.req_type != null">{{steerage.req_type ? 'Ishlagan' : 'Ishlamagan'}}</span>
                            <span v-else>Mavjud emas</span>
                        </td>
                        <td>
                            <span v-if="steerage.in_management != null">{{ steerage.in_management ? 'Ishlagan' : 'Ishlamagan'}}</span>
                             <span v-else>Mavjud emas</span>
                        </td>
                        <td>
                            <i v-if="steerage.status" class="success-icon el-icon-circle-check"></i> 
                            <i v-else class="danger-icon el-icon-circle-close"></i>
                        </td>
                    </tr>
                </tbody>
            </table>

				<!-- Diplom ma'lumotlari -->
				<table class="rez-khan-table" v-if="has_diploma">
                <thead>
                    <tr>
                        <th  colspan="3" class="text-center">Diplom ma'lumotlari</th>
                    </tr>
                    <tr>
                        <th>Diplom seriyasi va raqami</th>
								<th>Diplom berilgan sana</th>
								<th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(diplom, index) in diploma.data" :key="'diploma'+index">
								<td>
									{{ diplom.diplom_serial_number ? diplom.diplom_serial_number : 'Mavjud emas'}}
								</td>
								<td>
									{{ diplom.date_of_give_diploma ? diplom.date_of_give_diploma : 'Mavjud emas'}}
								</td>
								<td>
									<i v-if="diplom.status" class="success-icon el-icon-circle-check"></i> 
									<i v-else class="danger-icon el-icon-circle-close"></i>
								</td>
							</tr>
                </tbody>
            </table>

            <!-- Sterilizatsiya hamshirasi tekshiruv natijasi -->
            <div v-show="has_certificate">
                <div>
                    <table class="rez-khan-table">
                        <thead>
                            <tr>
                                <th colspan="3" class="text-center">Sertifikat ma'lumotlari</th>
                            </tr>
                            <tr>
                                <th>Sertifikat seriyasi va raqami</th>
                                <th>Sertifikat berilgan sana</th>
                                <th>Sertifikat amal qilish muddati</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(cte, index) in certificate.data" :key="index">
                                <td>
                                    {{ cte.certificate_serial_number ? cte.certificate_serial_number : 'Mavjud emas'}}
                                </td>
                                <td>
                                    {{ cte.certificate_date ? cte.certificate_date : 'Mavjud emas'}}
                                </td>
                                <td>
                                    {{ cte.validity_period ? cte.validity_period : 'Mavjud emas'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Sterilizatsiya hamshirasi SHARTNOMA asosida -->
                <div v-if="certificate.type == 'deal'">
                    <table class="rez-khan-table" v-if="certificate.hasOwnProperty('deal')">
                        <thead>
                            <tr>
                                <th colspan="3" class="text-center">Shartnoma ma'lumotlari</th>
                            </tr>
                            <tr>
                                <th>Shartnoma tuzilgan tibbiy muassasa nomi</th>
                                <th>Shartnoma raqami</th>
                                <th>Shartnoma amal qilish muddati</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {{ certificate.deal.deal_clinic ? certificate.deal.deal_clinic.legal_entity_name : 'Mavjud emas'}}
                                </td>
                                <td>
                                    {{ certificate.deal ? certificate.deal.deal_number : 'Mavjud emas'}}
                                </td>
                                <td>
                                    {{ certificate.deal ? certificate.deal.validity_period : 'Mavjud emas'}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['selectedItem', 'drawer'],
    data() {
        return {
            dialogPassportVisible: false,
            dialogInnVisible: false,
            passport: {},
            has_passport: true,
            inn: {},
            has_inn: true,
            has_region: true,
            region: {},
            experience: {},
            has_experience: true,
            educations: {},
            has_educations: true,
            steerage: {},
            has_steerage: true,
            category: {},
            has_category: true,
            degree: {},
            has_degree: true,
            elem: {},
            prosent_color_doctor: '',
            has_certificate: true,
            certificate: {},
				has_diploma: true,
				diploma: {}
        }
    },
    methods: {
        opened (){
            this.selectedColor();
            this.elem = this.selectedItem.result;
            if (this.elem) {
                if (this.elem.passport_and_series) {
                    this.passport = this.elem.passport_and_series;
                }
                else{ this.has_passport = false; }
                if (this.elem.inn) {
                    this.inn = this.elem.inn;
                }
                else this.has_inn = false;
                if (this.elem.region) {
                    this.region = this.elem.region;
                } else this.has_region = false;
                if (this.elem.work_experience) {
                    this.experience = this.elem.work_experience;
                }
                else this.has_experience = false;
                if (this.elem.educations) {
                    this.educations = this.elem.educations;
                }
                else this.has_educations = false;
                if (this.elem.qualification_category) {
                    this.category = this.elem.qualification_category;
                }
                else this.has_category = false;
                if (this.elem.academic_degree) {
                    this.degree = this.elem.academic_degree;
                }
                else this.has_degree = false;
                if (this.elem.steerage) {
                    this.steerage = this.elem.steerage;
                }
                else this.has_steerage = false; 
                if (this.elem.certificates) {
                    this.certificate = this.elem.certificates;
                }
                else this.has_certificate = false;
					 if (this.elem.diploma) {
						this.diploma = this.elem.diploma;
					 }
					 else this.has_diploma = false;
            }
        },
        selectedColor(){
            if (this.selectedItem.match_prosent <= 55) {
                this.prosent_color_doctor = 'danger-bg';
            }
            else if(this.selectedItem.match_prosent > 55 && this.selectedItem.match_prosent <= 85){
                  this.prosent_color_doctor = 'warning-bg';
            }
            else this.prosent_color_doctor = 'success-bg';
        },
        closed() {
            this.passport = {};
            this.inn = {};
            this.experience = {};
            this.educations = {};
            this.steerage = {};
            this.category = {};
            this.degree = {}; 
            this.elem = {};
            this.region = {};
            this.certificate = {};
				this.diploma = {};
            this.has_passport = true;
            this.has_inn = true;
            this.has_experience = true;
            this.has_educations = true;
            this.has_steerage = true;
            this.has_category = true;
            this.has_degree = true;
            this.has_region = true;
            this.has_certificate = true;
				this.has_diploma = true;
        },
        parent() {
          return this.$parent.$parent;
        },
        close() {                    
          this.parent().closeDrawer(this.drawer);       
        },
    }
}
</script>

<style>
.success-icon {
    font-size: 20px;
    color: green;
}
.danger-icon {
  font-size: 20px;
    color: red;  
}

</style>