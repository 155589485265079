<template >
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                <div class="title-internal-block font-1">{{$t('message.name_of_clinic')}}: {{ clinic.legal_entity_name }}</div>
            </div>
            <div>
                <el-button type="warning" size="medium" @click="close()" plain>
                    Yopish
                </el-button>
            </div>
        </div>

        <div class="modal-content">
            <div class="card-body p30">
                <div class="internal-sections-table licenseRequiments-main rez-khan">
                    <el-tabs v-model="activeName">
                        <el-tab-pane label="Tibbiy muassasa" name="first">
                            <el-row class="bor-bottom-d-s" :gutter="20">
                                <el-col :span="6">
                                    <div class="text-t-e">
                                       Tashkiliy shakli
                                    </div>
                                </el-col>
                                <el-col :span="18">
                                    <div class="text-t-e text-right">
                                        <span v-if="clinic.clinic_type">Davlat klinika</span>
                                        <span v-else>Xususiy klinika</span>
                                    </div>
                                </el-col>
                            </el-row> 

                            <el-row class="bor-bottom-d-s" :gutter="20">
                                <el-col :span="6">
                                    <div class="text-t-e">
                                        Tibbiy tashkilot turi
                                    </div>
                                </el-col>
                                <el-col :span="18">
                                    <div class="text-t-e text-right">
                                        {{clinic.medical_organization_type ? clinic.medical_organization_type.name : ''}}
                                    </div>
                                </el-col>
                            </el-row> 

                            <el-row class="bor-bottom-d-s" :gutter="20">
                                <el-col :span="6">
                                    <div class="text-t-e">
                                      Telefon raqami
                                    </div>
                                </el-col>
                                <el-col :span="18">
                                    <div class="text-t-e text-right">
                                      {{ clinic.phone_number }}
                                    </div>
                                </el-col>
                            </el-row> 

                            <el-row class="bor-bottom-d-s" :gutter="20">
                                <el-col :span="6">
                                    <div class="text-t-e">
                                        INN raqami
                                    </div>
                                </el-col>
                                <el-col :span="18">
                                    <div class="text-t-e text-right">
                                       {{clinic.inn}}
                                    </div>
                                </el-col>
                            </el-row>

                            <el-row class="bor-bottom-d-s" :gutter="20">
                                <el-col :span="6">
                                    <div class="text-t-e">
                                       Tibbiy muassasa yuridik manzili
                                    </div>
                                </el-col>
                                <el-col :span="9">
                                    <div class="text-t-e text-right">
                                        <span><b>Viloyat:</b> {{clinic.region ? clinic.region.region_name : ''}}</span>
                                    </div>
                                </el-col>
                                 <el-col :span="9">
                                    <div class="text-t-e text-right">
                                        <span><b>Shahar va Tuman:</b> {{clinic.city_district ? clinic.city_district.name : ''}}</span>
                                    </div>
                                </el-col>
                            </el-row>

                            <el-row class="bor-bottom-d-s" :gutter="20">
                                <el-col :span="6">
                                    <div class="text-t-e">
                                       Tibbiy muassasa faoliyat manzili
                                    </div>
                                </el-col>
                                <el-col :span="9">
                                    <div class="text-t-e text-right">
                                        <span><b>Viloyat:</b> {{clinic.work_region ? clinic.work_region.region_name : ''}}</span>
                                    </div>
                                </el-col>
                                 <el-col :span="9">
                                    <div class="text-t-e text-right">
                                        <span><b>Shahar va Tuman:</b> {{clinic.work_city_district  ? clinic.work_city_district.name : ''}}</span>
                                    </div>
                                </el-col>
                            </el-row> 
                        </el-tab-pane>

                        <el-tab-pane label="Xodimlar" name="second">
                            <el-row class="bor-bottom-d-s" v-for="(item, index) in items" :key="index">
                                <div class="title-f-l">
                                    <p class="mb-0">Litsenziya nomi: <b>{{item.license ? item.license.name : ''}} </b></p>
                                    <div class="right-rez" :class="prosentColorMain(item.license_procent)"><span><b> {{item.license_procent}} % </b> </span></div>
                                    <!-- warning-bg -->
                                </div>
                                <el-collapse accordion class="licenseRequiments-collapse" v-for="(doctor, index) in item.report_doctors" :key="'doctors-'+index">
                                    <el-collapse-item :name="'doctors-'+index">
                                        <template slot="title">
                                            <div class="text-t-e">
                                                <p style="font-weight: 400;">Xodim turi: <b>{{doctor.type_of_employee ? doctor.type_of_employee.name : ''}}</b></p> 
                                            </div>
                                        </template>
                                        <div>
                                            <el-row>
                                                <el-col :span="24">
                                                    <div class="text-t-e">
                                                        <p>Xodimlar yo'nalishi: <b> {{doctor.direction ? doctor.direction.name : ''}}</b></p>  
                                                    </div>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="8">
                                                    <div class="text-t-e">
                                                        <p>Mavjud xodimlar soni: <b> {{doctor.count ? ((doctor.count.count ? doctor.count.count : 'Mavjud emas')) : 'Mavjud emas'}} 
                                                            <span v-if="doctor.count.count"> ta</span></b>
                                                        </p>  
                                                    </div>
                                                </el-col>
                                                <el-col :span="12">
                                                    <div class="text-t-e">
                                                        <p>Talab etilgan xodimlar soni: <b> {{doctor.count ? (doctor.count.until ? doctor.count.until : 'Mavjud emas') : 'Mavjud emas'}}</b>
                                                            <span v-if="doctor.count.until"> ta</span>
                                                        </p>  
                                                    </div>
                                                </el-col>
                                                <el-col :span="4">
                                                    <div class="text-t-e">
                                                        <i v-if="doctor.count.status" class="success-icon el-icon-circle-check"></i> 
                                                        <i v-else class="danger-icon el-icon-circle-close"></i>  
                                                    </div>
                                                </el-col>
                                            </el-row>
                                            <el-row v-for="(information, index) in doctor.all_information" :key="'information-'+index">
                                                <el-col :span="8">
                                                    <div class="text-t-e">
                                                    <p>F.I.SH:<b> {{ information.surname ? information.surname : '' }} {{ information.name ? information.name : '' }}
                                                        {{information.lastname ? information.lastname : ''}}</b></p>  
                                                    </div>
                                                </el-col>
                                                <el-col :span="6">
                                                    <div class="text-t-e">
                                                    <p>Passport: <b> {{ information.passport_number ? information.passport_number : 'Mavjud emas' }}</b></p>  
                                                    </div>
                                                </el-col>
                                                <el-col :span="3">
                                                    <div class="text-t-e">
                                                        <div class="success-bg"><span>Talab: <b>{{information.count_all_condition}}</b> ta</span></div>
                                                    </div>
                                                </el-col>
                                                <el-col :span="3">
                                                    <div class="text-t-e">
                                                        <div class="warning-bg" :class="prosentColorMain(information.match_prosent)"><span>Bajarilgan talab: <b>{{information.match_count_condition}}</b> ta</span></div>
                                                    </div>
                                                </el-col>
                                                <el-col :span="4">
                                                    <div class="text-t-e" style="text-align: right">
                                                        <el-button type="text" @click="showReportDoctor(information)">Tafsilotlarni ko'rish <i class="el-icon-top-right"></i> </el-button>
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </el-collapse-item>
                                </el-collapse>
                            </el-row>
                        </el-tab-pane>

                        <el-tab-pane label="Tibbiy Texnikalar" name="third">
                            <el-row class="bor-bottom-d-s" v-for="(item, index) in items" :key="'techniques-'+index">
                                <div class="title-f-l">
                                    <p class="mb-0">Litsenziya nomi: <b>{{item.license ? item.license.name : ''}} </b></p>
                                    <div class="right-rez warning-bg" :class="prosentColorMain(item.license_procent)"><span><b> {{item.license_procent}} % </b> </span></div>
                                </div>
                                <el-collapse accordion class="licenseRequiments-collapse" v-for="(technique, index) in item.report_techniques" :key="'techniques-'+index">
                                    <el-collapse-item :name="'technique-'+index">
                                        <template slot="title">
                                            <el-row>
                                                <el-col :span="12">
                                                    <div class="text-t-e">
                                                        <p>Texnika turi: {{ technique.technique_type ? technique.technique_type.name : ''}}</p> 
                                                    </div>
                                                </el-col>
                                            </el-row> 
                                        </template>
                                        <div>
                                            <el-row>
                                                <el-col :span="8">
                                                    <div class="text-t-e">
                                                        <p>Mavjud texnikalar soni: <b> {{technique.count ? technique.count.count : ''}} ta</b></p>  
                                                    </div>
                                                </el-col>
                                                <el-col :span="12">
                                                    <div class="text-t-e">
                                                        <p>Talab etilgan texnikalar soni: <b> {{technique.count ? (technique.count.until + ' ta') : ''}}</b></p>  
                                                    </div>
                                                </el-col>
                                                <el-col :span="4">
                                                    <div class="text-t-e">
                                                        <i v-if="technique.count.status" class="success-icon el-icon-circle-check"></i> 
                                                        <i v-else class="danger-icon el-icon-circle-close"></i>  
                                                    </div>
                                                </el-col>
                                            </el-row>
                                            <el-row v-for="(information, index) in technique.all_information" :key="'infor_technique-'+index">
                                                <el-col :span="7">
                                                    <div class="text-t-e">
                                                    <p>Nomi: {{ information.name ? information.name : '' }}</p>  
                                                    </div>
                                                </el-col>
                                                <el-col :span="7">
                                                    <div class="text-t-e">
                                                    <p>Seriya va Raqami: {{ information.series_and_number ? information.series_and_number : 'Mavjud emas' }}</p>   
                                                    </div>
                                                </el-col>
                                                <el-col :span="3">
                                                    <div class="text-t-e">
                                                        <div class="success-bg"><span>Talab: <b>{{information.count_all_condition}}</b> ta</span></div>
                                                    </div>
                                                </el-col>
                                                <el-col :span="3">
                                                    <div class="text-t-e">
                                                        <div class="warning-bg" :class="prosentColorMain(information.match_prosent)"><span>Bajarilgan talab: <b>{{information.match_count_condition}}</b> ta</span></div>
                                                    </div>
                                                </el-col>
                                                <el-col :span="4">
                                                    <div class="text-t-e">
                                                    <el-button type="text" @click="showReportTechnique(information)">Tafsilotlarni ko'rish <i class="el-icon-top-right"></i></el-button>
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </el-collapse-item>
                                </el-collapse>
                            </el-row> 
                        </el-tab-pane>

                        <el-tab-pane label="Tibbiy uskunalar" name="fourth">
                            <el-row class="bor-bottom-d-s" v-for="(item, index) in items" :key="'equipments'+index">
                                <div class="title-f-l">
                                    <p class="mb-0">Litsenziya nomi: <b>{{item.license ? item.license.name : ''}} </b></p>
                                    <div class="right-rez warning-bg" :class="prosentColorMain(item.license_procent)"><span><b> {{item.license_procent}} % </b> </span></div>
                                </div>
                                <el-collapse accordion class="licenseRequiments-collapse" v-for="(equipment, index) in item.report_equipments" :key="'equipments-'+index">
                                    <el-collapse-item :name="'equipment-'+index">
                                        <template slot="title">
                                            <el-row>
                                                <el-col :span="12">
                                                    <div class="text-t-e">
                                                        <p>Uskuna turi: {{ equipment.equipment_type ? equipment.equipment_type.name : ''}}</p> 
                                                    </div>
                                                </el-col>
                                                <el-col :span="12">
                                                </el-col>
                                            </el-row> 
                                        </template>
                                        <div>
                                            <el-row>
                                                <el-col :span="8">
                                                    <div class="text-t-e">
                                                        <p>Mavjud uskunalar soni: <b> {{equipment.count ? equipment.count.count : ''}} ta</b></p>  
                                                    </div>
                                                </el-col>
                                                <el-col :span="12">
                                                    <div class="text-t-e">
                                                        <p>Talab etilgan uskunalar soni: <b> {{equipment.count ? (equipment.count.until + ' ta') : ''}}</b></p>  
                                                    </div>
                                                </el-col>
                                                <el-col :span="4">
                                                    <div class="text-t-e">
                                                        <i v-if="equipment.count.status" class="success-icon el-icon-circle-check"></i> 
                                                        <i v-else class="danger-icon el-icon-circle-close"></i>  
                                                    </div>
                                                </el-col>
                                            </el-row>
                                            <el-row v-for="(information, index) in equipment.all_information" :key="'infor_equipment-'+index">
                                                <el-col :span="7">
                                                    <div class="text-t-e">
                                                    <p>Nomi: {{ information.name ? information.name : '' }}</p>  
                                                    </div>
                                                </el-col>
                                                <el-col :span="7">
                                                    <div class="text-t-e">
                                                    <p>Seriya va Raqami: {{ information.series_and_number ? information.series_and_number : 'Mavjud emas' }}</p>   
                                                    </div>
                                                </el-col>
                                                 <el-col :span="3">
                                                    <div class="text-t-e">
                                                        <div class="success-bg"><span>Talab: <b>{{information.count_all_condition}}</b> ta</span></div>
                                                    </div>
                                                </el-col>
                                                <el-col :span="3">
                                                    <div class="text-t-e">
                                                        <div class="warning-bg" :class="prosentColorMain(information.match_prosent)"><span>Bajarilgan talab: <b>{{information.match_count_condition}}</b> ta</span></div>
                                                    </div>
                                                </el-col>
                                                <el-col :span="4">
                                                    <div class="text-t-e">
                                                        <el-button type="text" @click="showReportEquipment(information)">Tafsilotlarni ko'rish <i class="el-icon-top-right"></i></el-button>
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </el-collapse-item>
                                </el-collapse>
                            </el-row> 
                        </el-tab-pane>
                    </el-tabs>

                    <template>
                        <!-- REPORT DOCTOR -->
                        <el-drawer
                            title="I have a nested table inside!"
                            :visible.sync="dialogReportDoctorVisible"
                            :append-to-body="true"
                            direction="rtl"
                            size="97%"
                            ref="drawerReportDoctor"
                            :with-header="false"
                            @opened="drawerOpened('drawerReportDoctorChild')"
                            @closed="drawerClosed('drawerReportDoctorChild')"
                            >
                        <report-doctor 
                            :selectedItem="reportDoctor"
                            ref="drawerReportDoctorChild"
                            drawer="drawerReportDoctor"
                        ></report-doctor>
                        </el-drawer>

                        <!-- REPORT TECNIQUE -->
                        <el-drawer
                            title="I have a nested table inside!"
                            :visible.sync="dialogReportTechniqueVisible"
                            :append-to-body="true"
                            direction="rtl"
                            size="97%"
                            :with-header="false"
                            ref="drawerReportTechnique"
                            @opened="drawerOpened('drawerReportTechniqueChild')"
                            @closed="drawerClosed('drawerReportTechniqueChild')"
                            >
                        <report-technique 
                            :selectedItem="reportTechnique"
                            ref="drawerReportTechniqueChild"
                            drawer="drawerReportTechnique"
                        ></report-technique>
                        </el-drawer>

                        <!-- REPORT EQUIPMENT -->
                        <el-drawer
                            title="I have a nested table inside!"
                            :visible.sync="dialogReportEquipemntVisible"
                            :append-to-body="true"
                            direction="rtl"
                            size="97%"
                            :with-header="false"
                            ref="drawerReportEquipment"
                            @opened="drawerOpened('drawerReportEquipmentChild')"
                            @closed="drawerClosed('drawerReportEquipmentChild')"
                            >
                        <report-equipment 
                            :selectedItem="reportEquipment"
                            ref="drawerReportEquipmentChild"
                            drawer="drawerReportEquipment"
                        ></report-equipment>
                        </el-drawer>
                    </template>
                </div>
            </div>
        </div>
        <!-- end app-modal__body -->
    </div>
</template>
<script>
import drawer from "@/utils/mixins/drawer";
import reportDoctor from './dialog/reportDoctor';
import reportTechnique from './dialog/reportTechnique';
import reportEquipment from './dialog/reportEquipment';
export default {
    mixins: [drawer],
    props: {
        check_results: {
            type: Object,
            required: true
        }
    },
    components: {reportDoctor, reportTechnique, reportEquipment},
    data() {
        return {
            activeName: 'first',
            items: [],
            dialogReportDoctorVisible: false,
            reportDoctor: [],
            dialogReportTechniqueVisible: false,
            reportTechnique: [],
            dialogReportEquipemntVisible: false,
            reportEquipment: [],
            clinic: {},
            prosent_color_main: ''
        }
    },
    methods: {
        afterOpen(){
            this.clinic = this.check_results.clinic;
            this.items = this.check_results['result'];
        },
         showReportDoctor(items){
            this.dialogReportDoctorVisible = true;
            this.reportDoctor = items;
        },
        showReportTechnique(items){
            this.dialogReportTechniqueVisible = true;
            this.reportTechnique = items;
        },
        showReportEquipment(items) {
            this.dialogReportEquipemntVisible = true;
            this.reportEquipment = items;
        },
        prosentColorMain(match_prosent){
             if (match_prosent <= 55) {
                return 'danger-bg';
            }
            else if(match_prosent > 55 && match_prosent <= 85){
                  return 'warning-bg';
            }
            else return 'success-bg';
        },
        drawerOpened(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened();
                }
            }
        },
        drawerClosed(ref) {
            if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].closed)) {
                    this.$refs[ref].closed();
                }
            }
        },
        closeDrawer(drawer) {
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
        afterLeave(){
            this.clinic = {};
            this.items = [];
        }
    }
}
</script>
<style lang="scss">
 .rez-khan{
    .el-collapse-item{
        padding: 0;
    }
    .el-collapse-item__header{
        padding: 20px !important;
        margin: 0 !important;
        background: #f5f7fb;
        // border-bottom: 1px solid #bfbfbf;
        font-weight: 700;
    }
    .el-collapse-item__content{
        padding: 20px;
    }
    .el-collapse-item__content>div>.el-row{
        border-bottom: 1px dashed  #e3e3e3;;
        padding-top: 13px;
        padding-bottom: 10px;
        
        .el-button{
            margin: 0;
            padding: 0;
        }
    }
  }

  .success-icon {
    font-size: 20px;
    color: green;
    }
    .danger-icon {
    font-size: 20px;
        color: red;  
    }
.delet-icon {
    font-size: 30px;
    position: absolute;
    right: -24px;
    top: 35px;
    color: red;
}
</style>