<template>
    <div>
         <div class="modal-header">
            <div class="modal-title-my flex-1">
                <div class="title-f-l">
                    <p class="mb-0">Uskuna nomi: <b>{{selectedItem.name ? selectedItem.name : ''}} </b></p>
                    <div class="right-rez add-right-rez warning-bg" :class="prosent_color_equipment"><span><b> {{ selectedItem.match_prosent }} % </b> </span></div>
                </div>
            </div>
            <div>
                <el-button type="danger" @click="close()" plain>
                    Yopish
                </el-button>
            </div>
        </div>
        <div class="p-20">
            <!-- Seriya va raqami -->
            <table class="rez-khan-table" v-if="has_series_and_number">
                <thead>
                    <tr>
                        <th  colspan="2" class="text-center">Seriya va raqami</th>
                    </tr>
                    <tr>
                        <th>Raqam</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                           {{ series.series_and_number ? series.series_and_number : 'Mavjud emas' }}
                        </td>
                        <td>
                            <i v-if="series.status" class="success-icon el-icon-circle-check"></i> 
                            <i v-else class="danger-icon el-icon-circle-close"></i> 
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="series.hasOwnProperty('similler_series_and_number')">
                <table class="rez-khan-table" v-if="series.similler_series_and_number.length">
                <thead>
                    <tr>
                        <th colspan="2" class="text-center">Bir xil seriya va raqamlilar mavjud</th>
                    </tr>
                    <tr>
                        <th>Klinika</th>
                        <th>Nomi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item_equipment, index) in series.similler_series_and_number" :key="'series-'+index">
                        <td>
                            {{item_equipment.clinic}}
                        </td>
                        <td>
                           {{item_equipment.name}}
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>

            <!-- Pasport -->
            <table class="rez-khan-table" v-if="has_passport">
                <thead>
                    <tr>
                        <th  colspan="3" class="text-center">Pasport</th>
                    </tr>
                    <tr>
                        <th>Talab</th>
                        <th>Natija</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{passport.req_type ? 'Shart' : 'Shart emas'}}
                        </td>
                        <td>
                            {{passport.passport ? 'HA' : "Yo'q"}} 
                        </td>
                        <td>
                            <i v-if="passport.status" class="success-icon el-icon-circle-check"></i> 
                            <i v-else class="danger-icon el-icon-circle-close"></i> 
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- Qo'llanmasi -->
            <table class="rez-khan-table" v-if="has_manual">
                <thead>
                    <tr>
                        <th  colspan="3" class="text-center">Qo'llanmasi</th>
                    </tr>
                    <tr>
                        <th>Talab</th>
                        <th>Natija</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                           {{manual.req_type ? 'Shart' : 'Shart emas'}} 
                        </td>
                        <td>
                           {{manual.manual ? 'HA' : "Yo'q"}} 
                        </td>
                        <td>
                            <i v-if="manual.status" class="success-icon el-icon-circle-check"></i> 
                            <i v-else class="danger-icon el-icon-circle-close"></i> 
                        </td>
                    </tr>
                </tbody>
            </table>

             <!-- Guvohnomasi -->
            <table class="rez-khan-table" v-if="has_document">
                <thead>
                    <tr>
                        <th  colspan="3" class="text-center">Guvohnomasi</th>
                    </tr>
                    <tr>
                        <th>Talab</th>
                        <th>Natija</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                           {{document.req_type ? 'Shart' : 'Shart emas'}} 
                        </td>
                        <td>
                            {{document.document ? 'HA' : "Yo'q"}} 
                        </td>
                        <td>
                            <i v-if="document.status" class="success-icon el-icon-circle-check"></i> 
                            <i v-else class="danger-icon el-icon-circle-close"></i> 
                        </td>
                    </tr>
                </tbody>
            </table>

             <!-- Guvohnomasi va raqami -->
            <table class="rez-khan-table" v-if="has_document_number">
                <thead>
                    <tr>
                        <th  colspan="2" class="text-center">Guvohnomasi va raqami</th>
                    </tr>
                    <tr>
                        <th>Raqam</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{document_number.document_number ? document_number.document_number : 'Mavjud emas'}}
                        </td>
                        <td>
                            <i v-if="document_number.status" class="success-icon el-icon-circle-check"></i> 
                            <i v-else class="danger-icon el-icon-circle-close"></i> 
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="document_number.hasOwnProperty('similler_document_number')">
                <table class="rez-khan-table" v-if="document_number.similler_document_number.length">
                    <thead>
                        <tr>
                            <th colspan="2" class="text-center">Bir xil Guvohnoma raqamlilar mavjud</th>
                        </tr>
                        <tr>
                            <th>Klinika</th>
                            <th>Nomi</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item_equipment, index) in document_number.similler_document_number" :key="'document_number-'+index">
                            <td>
                                {{item_equipment.clinic}}
                            </td>
                            <td>
                            {{item_equipment.name}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            <!-- Sertifikati -->
            <table class="rez-khan-table" v-if="has_certificate">
                <thead>
                    <tr>
                        <th  colspan="3" class="text-center">Sertifikati</th>
                    </tr>
                    <tr>
                        <th>Talab</th>
                        <th>Natija</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                           {{certificate.req_type ? 'Shart' : 'Shart emas'}} 
                        </td>
                        <td>
                            {{certificate.certificate ? 'HA' : "Yo'q"}} 
                        </td>
                        <td>
                            <i v-if="certificate.status" class="success-icon el-icon-circle-check"></i> 
                            <i v-else class="danger-icon el-icon-circle-close"></i> 
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- Sertifikati va raqami -->
            <table class="rez-khan-table" v-if="has_certificate_number">
                <thead>
                    <tr>
                        <th  colspan="2" class="text-center">Sertifikati va raqami</th>
                    </tr>
                    <tr>
                        <th>Raqam</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{certificate_number.certificate_number ? certificate_number.certificate_number : 'Mavjud emas'}}
                        </td>
                        <td>
                            <i v-if="certificate_number.status" class="success-icon el-icon-circle-check"></i> 
                            <i v-else class="danger-icon el-icon-circle-close"></i> 
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-if="certificate_number.hasOwnProperty('similler_certificate_number')">
                <table class="rez-khan-table" v-if="certificate_number.similler_certificate_number.length">
                <thead>
                    <tr>
                        <th colspan="2" class="text-center">Bir xil Sertifikat raqamlilar mavjud</th>
                    </tr>
                    <tr>
                        <th>Klinika</th>
                        <th>Nomi</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item_equipment, index) in certificate_number.similler_certificate_number" :key="'certificate_number-'+index">
                        <td>
                            {{item_equipment.clinic}}
                        </td>
                        <td>
                           {{item_equipment.name}}
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>

            <!-- Mulk huquqi -->
            <table class="rez-khan-table" v-if="has_ownership">
                <thead>
                    <tr>
                        <th  colspan="3" class="text-center">Mulk huquqi</th>
                    </tr>
                    <tr>
                        <th>Talab</th>
                        <th>Mulk egaligi</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{ownership.req_ownership ? 'Shart' : 'Shart emas'}} 
                        </td>
                        <td>
                            {{ownership.equipment_ownership ? ownership.equipment_ownership : 'Mavjud emas'}}
                        </td>
                        <td>
                            <i v-if="ownership.status" class="success-icon el-icon-circle-check"></i> 
                            <i v-else class="danger-icon el-icon-circle-close"></i> 
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- Holati -->
            <table class="rez-khan-table" v-if="has_condition">
                <thead>
                    <tr>
                        <th  colspan="3" class="text-center">Holati</th>
                    </tr>
                    <tr>
                        <th>Talab</th>
                        <th>Holati</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {{condition.req_condition ? 'Shart' : "Shart emas"}} 
                        </td>
                        <td>
                            {{condition.equipment_ownership_name ? condition.equipment_ownership_name : 'Mavjud emas'}}
                        </td>
                        <td>
                            <i v-if="condition.status" class="success-icon el-icon-circle-check"></i> 
                            <i v-else class="danger-icon el-icon-circle-close"></i> 
                        </td>
                    </tr>
                </tbody>
            </table>

              <!-- Ishlab chiqarilgan sanasi -->
            <table class="rez-khan-table" v-if="has_date_of_manufacture">
                <thead>
                    <tr>
                        <th  colspan="2" class="text-center">Ishlab chiqarilgan sanasi</th>
                    </tr>
                    <tr>
                        <th>Ishlab chiqarilgan sanasi</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                           {{ date_of_manufacture.manufacture ? date_of_manufacture.manufacture : 'Mavjud emas' }}
                        </td>
                        <td>
                            <i v-if="date_of_manufacture.status" class="success-icon el-icon-circle-check"></i> 
                            <i v-else class="danger-icon el-icon-circle-close"></i> 
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
    props:['selectedItem', 'drawer'],
    data() {
        return {
            series: {},
            has_series_and_number: true,
            passport: {},
            has_passport: true,
            manual: {},
            has_manual: true,
            document: {},
            has_document: true,
            document_number: {},
            has_document_number: true,
            certificate: {},
            has_certificate: true,
            certificate_number: {},
            has_certificate_number: true,
            ownership: {},
            has_ownership: true,
            condition: {},
            has_condition: true,
            date_of_manufacture: {},
            has_date_of_manufacture: true,
            elem: {},
            prosent_color_equipment: ''
        }
    }, 
    methods: {
        opened (){
            this.selectedColor();
            this.elem = this.selectedItem.result;
            if (this.elem) {
                if (this.elem.series_and_number) {
                this.series = this.elem.series_and_number;
                } else this.has_series_and_number = false; 
                if (this.elem.document_number) {
                    this.document_number = this.elem.document_number;
                } else this.has_document_number = false;
                if (this.elem.certificate_number) {
                    this.certificate_number = this.elem.certificate_number;
                } else this.has_certificate_number = false;
                if (this.elem.passport) {
                    this.passport = this.elem.passport;
                } else this.has_passport = false;
                if (this.elem.manual) {
                    this.manual = this.elem.manual;
                } else this.has_manual = false;
                if (this.elem.document) {
                    this.document = this.elem.document;
                } else this.has_document = false;
                if (this.elem.certificate) {
                    this.certificate = this.elem.certificate;
                } else this.has_certificate = false;
                if (this.elem.property_ownership) {
                    this.ownership = this.elem.property_ownership;
                } else this.has_ownership = false;
                if (this.elem.condition) {
                    this.condition = this.elem.condition;
                }else this.has_condition = false;
                if (this.elem.date_of_manufacture) {
                    this.date_of_manufacture = this.elem.date_of_manufacture;
                } else this.has_date_of_manufacture = false;
            }
        },
        selectedColor(){
            if (this.selectedItem.match_prosent <= 55) {
                this.prosent_color_equipment = 'danger-bg';
            }
            else if(this.selectedItem.match_prosent > 55 && this.selectedItem.match_prosent <= 85){
                  this.prosent_color_equipment = 'warning-bg';
            }
            else this.prosent_color_equipment = 'success-bg';
        },
        closed(){
            this.series = {};
            this.passport = {};
            this.manual = {};
            this.document = {};
            this.document_number = {};
            this.certificate = {};
            this.certificate_number = {};
            this.ownership = {};
            this.condition = {};
            this.date_of_manufacture = {};
            this.has_series_and_number = true;
            this.has_passport = true;
            this.has_manual = true;
            this.has_document = true;
            this.has_document_number = true;
            this.has_certificate = true;
            this.has_certificate_number = true;
            this.has_ownership = true;
            this.has_condition = true;
            this.has_date_of_manufacture = true;
        },
        parent() {
          return this.$parent.$parent;
        },
        close() {                    
          this.parent().closeDrawer(this.drawer);       
        },
    }
}
</script>

<style>
.success-icon {
    font-size: 20px;
    color: green;
}
.danger-icon {
  font-size: 20px;
    color: red;  
}
</style>