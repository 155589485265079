<template>
    <div class="internal-sections add-style-blocks-mi">
        <div class="internal-sections-top">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="d-flexk a-i-center justify-content-between mb20">
                        <div class="title-internal-block">Litsenziya olish uchun ariza yuborish</div>
                        <div class="text-right">
                            <el-button plain type="warning" @click="submit(true, true)" :loading="loadingButtonCheck" style="padding-left: 5rem; padding-right: 5rem; float:left;"> 
                                Tekshirish  
                                <i class="el-icon-refresh"></i>
                            </el-button>
                            <el-button type="success" @click="submit(true, false)" :loading="loadingButton" style="padding-left: 5rem; padding-right: 5rem"> 
                                Jo'natish
                                <i class="el-icon-position"></i> 
                            </el-button>
                        </div>
                    </div>
                </el-col>
            </el-row>
            
            <el-form ref="form" :model="form" :rules="rules" status-icon>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="Tibbiy muassasa" prop="clinic_id">
                            <el-select
                                v-model="form.clinic_id"
                                filterable
                                clearable
                                remote
                                :placeholder="$t('message.clinics')"
                                :remote-method="remoteMethod"
                                @change="selectedClinicId(form.clinic_id, true)"
                                :loading="loading"
                                size="large"
                                class="w-100">
                                <el-option
                                v-for="item in clinics"
                                :key="item.id"
                                :label="item.legal_entity_name"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        
                        <el-form-item v-if="filtered_filial.length > 0" label="Tibbiy muassasa filyali">
                            <el-select
                                v-model="form.filial_ids"
                                placeholder="Filial"
                                clearable
                                filterable
                                class="w-100"
                                multiple
                            >
                                <el-option
                                    v-for="(
                                        filial, index
                                    ) in filtered_filial"
                                    :key="'filial-' + index"
                                    :label="
                                        filial.legal_entity_name +
                                        ' / ' +
                                        filial.brand_name
                                    "
                                    :value="filial.id"
                                ></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Litsenziya" prop="procedure_ids">
                            <el-select 
                                class="w-100" 
                                v-model="form.procedure_ids"  
                                filterable 
                                clearable 
                                placeholder="Litsenziya"
                                @change="selectedLicenseId"
                                multiple
                            >
                            <el-option
                                v-for="(item, index) in inventory" :key="index"
                                :label="item.name"
                                :value="item.id"
                                ></el-option> 
                            </el-select>
                            <el-tag size="medium" class="mr-1" v-for="(item, index) in requirements" :key="index">
                                <div>{{item.name}}</div>
                            </el-tag>
                        </el-form-item>

                        <el-form-item label="Bosh shifokorni qaysi litsenziya talabi asosida tekshirilishi" prop="check_license_id">
                            <el-select 
                                class="w-100" 
                                v-model="form.check_license_id"  
                                filterable 
                                clearable 
                                placeholder="Bosh shifokorni qaysi litsenziya talabi asosida tekshirilishi"
                            >
                                <el-option 
                                    v-for="(item, index) in check_kind_of_licenses" :key="index"
                                    :label="item.name"
                                    :value="item.id"
                                ></el-option> 
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Sterilizatsiya hamshirasini tekshirish turi" prop="nurse_type">
                            <el-select class="w-100" v-model="form.nurse_type" placeholder="Sterilizatsiya hamshirasini tekshirish">
                                <el-option label="Tibbiy muassasa o'zidan" :value="true"></el-option>
                                <el-option label="Shartnoma asosida" :value="false"></el-option>
                            </el-select>
                        </el-form-item>
                        
                        <el-form-item label="Holat" prop="application_type">
                            <el-select class="w-100" v-model="form.application_type" placeholder="Holat">
                                <el-option label="Yangi" :value="true" :disabled="has_exists_id"></el-option>
                                <el-option label="Qayta ariza" :value="false"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Ko'rib chiqish vaqti" prop="review_time_id">
                            <el-select class="w-100" v-model="form.review_time_id" placeholder="Ko'rib chiqish vaqti">
                                <el-option label="Oddiy" :value="1"></el-option>
                                <el-option label="Tezlashtirilgan" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>

        <div class="my-modal-big">
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawer"
                :wrapperClosable="false"
                size="97%"
                ref="drawerCreate"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')"
            >
            <crm-create ref="drawerCreateChild" drawer="drawerCreate" :check_results="results"></crm-create>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import CrmCreate from './components/crm-create';
import _ from 'lodash';
export default {
    name: "submitApplications",
    components: {
        CrmCreate
    },
    computed: {
        ...mapGetters({
            model: 'submitApplications/model',
            rules: 'submitApplications/rules',
            filtered_filial: 'clinics/filtered_filial',
            inventory: 'licenseRequirements/inventory'
        })
    },
    data(){
        return {
            form: {},
            loading: false,
            clinics: [],
            requirements: [],
            loadingButton: false,
            loadingButtonCheck: false,
            check_kind_of_licenses: [],
            old_array: [],  
            drawer: false,
            has_exists_id: false,
            results: {}
        };
    },
    created() {
        this.form = JSON.parse(JSON.stringify(this.model));
        this.getInventory();
    },
    methods: {
        ...mapActions({
            remoteSearchMethod: 'doctors/remoteMethod',
            getFilteredFilials: 'clinics/filteredFilial',
            getInventory: 'licenseRequirements/inventory',
            getRequirements: 'licenseRequirements/getRequirements',
            save: 'submitApplications/store'
        }),

        submit(close = true, withParams){
            let ids = [];
            this.form.procedure_ids.map(item => {
                ids.push(item);
            });

            if (!_.isEmpty(this.requirements)) {
                _.map(this.requirements, item => {
                    ids.push(item.id);
                });
            }
            this.form.withParams = withParams;
            this.form['ids'] = ids;
        
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (withParams) {
                        this.loadingButtonCheck = true;
                    }else this.loadingButton = true;

                    this.save(this.form)
                    .then((res) => {
                        withParams ? this.loadingButtonCheck = false : this.loadingButton = false;
                        let data = res.data.result.data;
                        if (!_.isEmpty(data)) {
                            // console.log('after response', res);
                            this.results = data;
                            this.drawer = true;
                        }else{
                            this.$alert(res);
                            this.form = JSON.parse(JSON.stringify(this.model));
                            this.clinics = [];
                            this.requirements = [];
                            this.check_kind_of_licenses = [];
                            this.old_array = [];
                        }
                    }).catch((err) => {
                        this.loadingButton = false;
                        if (err.status == 422) {
                            if (!_.isEmpty(err.data.validation_errors)) {
                                let errors = err.data.validation_errors[0].clinic;
                                const h = this.$createElement;
                                let mistake = errors.map(mime => h('li', `${mime}`));
                                const message = h('ul', mistake);
                                    this.$message({
                                        message: message,
                                        type: 'warning',
                                        showClose: true,
                                        duration: 5000
                                    });     
                            }
                        }
                    });
                }
            });
        },

        remoteMethod(query){
            if (query) {
                this.loading = true;
                setTimeout(() => {
                    this.remoteSearchMethod({query: query})
                    .then((res) => {
                        if (res.clinics) {
                            this.loading = false;
                            this.clinics = res.clinics;
                        }
                    }).catch((err) => {
                        this.loading = false;
                        console.log(err);
                    }, 2000);
                });
            }else{
                this.clinics = [];
            }
        },

        selectedClinicId(clinic_id, withParametr){
            if (clinic_id) {
                let query = {clinic_id: clinic_id, withParametr: withParametr};
                this.getFilteredFilials(query).then((res) => {
                    if (res.has_exists_id) {
                        this.form.application_type = false;
                        this.has_exists_id = true;
                    }
                });    
            }else{
                this.clinics = [];
                this.form.application_type = true;
                this.has_exists_id = false; 
                this.$store.commit('clinics/EMPTY_FILIAL');
            }
        },

        selectedLicenseId(procedure_ids){
            if (_.isEmpty(this.old_array)) {
                // console.log('1 - shart');
                this.old_array = procedure_ids;
                let last_id = _.last(procedure_ids);
                if (last_id) {
                    let obj = _.find(this.inventory, function (o) {return o.id == last_id});
                    this.check_kind_of_licenses.push(obj);

                    this.getRequirements(parseInt(last_id)).then((res) => {
                        if (res.requirements && res.requirements.length != 0) {
                            res.requirements.forEach(item => {
                                if (!_.includes(this.form.procedure_ids, item.id) && !_.find(this.requirements, function (elem) { return elem.id == item.id; })) {
                                    this.requirements.push({id: item.id, name: item.name, procedure_id: item.procedure_id});
                                }
                                if (!_.find(this.check_kind_of_licenses, function (elem) { return elem.id == item.id; }) ) {
                                    this.check_kind_of_licenses.push({id: item.id, name: item.name, procedure_id: item.procedure_id});
                                }
                            });
                        }
                    });
                }
            }
            else if (this.old_array.length < procedure_ids.length){
                let status = false;
                let last_id = _.last(procedure_ids);

                if (!_.isEmpty(this.requirements)) {
                    _.forEach(this.requirements, item => {
                        if (item.id == last_id) {
                            this.form.procedure_ids.splice(_.indexOf(procedure_ids, last_id), 1);
                            status = true;
                        }
                    })
                }

                if (!status) {
                    this.old_array.push(last_id);
                    let obj = _.find(this.inventory, function (o) {return o.id == last_id});
                    this.check_kind_of_licenses.push(obj);

                    this.getRequirements(parseInt(last_id)).then((res) => {
                        if (res.requirements && res.requirements.length != 0) {
                            res.requirements.forEach(item => {
                                if (!_.includes(this.form.procedure_ids, item.id) && !_.find(this.requirements, function(elem) { return elem.id == item.id; })) {
                                    this.requirements.push({id: item.id, name: item.name, procedure_id: item.procedure_id});
                                }
                                if (!_.find(this.check_kind_of_licenses, function (o) {return o.id == item.id; }) 
                                    && !_.find(this.requirements, function (req) { return req.id == last_id; })) {
                                    this.check_kind_of_licenses.push({id: item.id, name: item.name, procedure_id: item.procedure_id});
                                }
                            });
                        }
                    });
                }
            }
            else if (this.old_array.length > procedure_ids.length) {
                let self = this;
                let procedure_id = _.head(_.difference(self.old_array, procedure_ids));
                let index = _.indexOf(self.old_array, procedure_id);
                self.old_array.splice(index, 1);

                for (let i = self.requirements.length - 1; i >= 0; i--) {
                    if (self.requirements[i].procedure_id == procedure_id) {
                        self.requirements.splice(i, 1);
                    }
                }

                for (let i = self.check_kind_of_licenses.length - 1; i >= 0; i--) {
                    if (self.check_kind_of_licenses[i]?.id == procedure_id) {
                        self.check_kind_of_licenses.splice(i, 1);
                        self.form.check_license_id = null;
                    }
                    if (self.check_kind_of_licenses[i]?.procedure_id) {
                        if (self.check_kind_of_licenses[i]?.procedure_id == procedure_id) {
                            self.check_kind_of_licenses.splice(i, 1);
                            self.form.check_license_id = null;
                        }
                    }
                }
            }
        },

        drawerOpened(ref) {
           if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].opened)) {
                    this.$refs[ref].opened();
                }
           }
        },

        drawerClosed(ref) {
             if (this.$refs[ref]) {
                if (_.isFunction(this.$refs[ref].closed)) {
                    this.$refs[ref].closed();
                }
           }
        },

        closeDrawer(drawer) {
            if (this.$refs[drawer] && _.isFunction(this.$refs[drawer].closeDrawer)) {
                this.$refs[drawer].closeDrawer();
            }
        },
    },
    beforeRouteLeave (to, from, next) {
        this.requirements = [];
        this.check_kind_of_licenses = [];
        this.old_array = [];  
        next();
    },
};
</script>

